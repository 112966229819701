import './StakingComponent.css';
import {useEffect, useState} from 'react';
import {ethers} from 'ethers';
import {
    getContractABI,
    getContractAddress, getTokenABI,
    getTokenAddress,
    isMetaMaskConnected,
    MAINNET,
    redirectTo,
    TESTNET
} from '../../utils';
import time from '../../../assets/time-icon.svg';
import apy from '../../../assets/apy-icon.svg';
import frog from '../../../assets/frog.png';
import {useForm} from 'react-hook-form';
import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css';

var bigDecimal = require('js-big-decimal');

const CONTRACT_ADDRESS = getContractAddress(MAINNET);
const CONTRACT_ABI = getContractABI();

const TOKEN_CONTRACT_ADDRESS = getTokenAddress(MAINNET);
const TOKEN_CONTRACT_ABI = getTokenABI();

function StakingComponent() {
    const [userAddress, setUserAddress] = useState('');
    const [walletBalance, setWalletBalance] = useState('0');
    const [stakedBalance, setStakedBalance] = useState('0');
    const [earnedRewards, setEarnedRewards] = useState('0');
    const [loggedIn, setLoggedIn] = useState(true);
    const [provider, setProvider] = useState({});

    const {register, getValues, resetField} = useForm();

    const fetchWalletData = async () => {
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const userAddress = await signer.getAddress();
            if (provider) {
                setProvider(provider);
                setUserAddress(userAddress);
            }
        } catch (e) {
            console.log('Error while fetching wallet data:', e);
            toastr.error('Oops! It seems there\'s an error fetching data from your wallet.', '', {displayDuration: 6000});
        }
    };

    const connectWallet = async () => {
        try {
            if (window.ethereum) {
                const connectedProvider = new ethers.BrowserProvider(window.ethereum);
                await connectedProvider.send('eth_requestAccounts', [{eth_accounts: {}}]);
                const signer = await connectedProvider.getSigner();
                const userAddress = await signer.getAddress();
                setUserAddress(userAddress);
                updateBalances();
                setProvider(connectedProvider);
                setLoggedIn(true);
                fetchContractData();
            } else {
                toastr.error('Web3 wallet not detected');
            }
        } catch (e) {
            console.log('Error while connecting wallet:', e);
            toastr.error('Oops! It seems there\'s an error connecting to your wallet.', '', {displayDuration: 6000});
        }
    };

    const updateBalances = async () => {
        try {
            const connectedProvider = new ethers.BrowserProvider(window.ethereum);
            const signer = await connectedProvider.getSigner();
            const userAddress = await signer.getAddress();

            const originalTokenBalance = await new ethers.Contract(TOKEN_CONTRACT_ADDRESS, TOKEN_CONTRACT_ABI, connectedProvider).balanceOf(userAddress);
            console.log('originalTokenBalance', originalTokenBalance);
            const finalTokenBalance = ethers.formatUnits(originalTokenBalance, 9);
            setWalletBalance(finalTokenBalance);

            const DAI = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, connectedProvider);
            const originalUserStakedBalance = await DAI.getUserStakedBalance(userAddress);
            console.log('originalUserStakedBalance', originalTokenBalance);
            const finalStakedBalance = ethers.formatUnits(originalUserStakedBalance, 9);
            setStakedBalance(finalStakedBalance);

            const originalEarnedRewards = await DAI.getUserEarnedRewards(userAddress);
            console.log('originalEarnedRewards:', originalEarnedRewards);
            const finalEarnedRewards = ethers.formatUnits(originalEarnedRewards, 9);
            setEarnedRewards(finalEarnedRewards);
        } catch (e) {
            console.log('Error while updating balances:', e);
        }
    };

    const toFixed = (value) => {
        return value.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
    };

    const fetchContractData = async () => {
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const userAddress = await signer.getAddress();
            const DAI = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);
            updateBalances();
        } catch (err) {
            console.log('Error while fetching contract data:', err);
            toastr.error(err, 'Oops! An error occurred', {displayDuration: 6000});
        }
    };

    useEffect(() => {
        if (window.ethereum) {
            isMetaMaskConnected().then(isConnected => {
                setLoggedIn(isConnected);
                if (isConnected) {
                    connectWallet();
                }
            });
            window.ethereum.on('chainChanged', (args) => {
                console.log('chainChanged event:', args);
                fetchWalletData();
            });
            window.ethereum.on('accountsChanged', (args) => {
                console.log('accountsChanged args', args);
                if (args.length === 0) {
                    window.ethereum.removeAllListeners('accountsChanged');
                    setProvider(null);
                    setUserAddress(null);
                    setLoggedIn(false);
                } else {
                    fetchWalletData();
                }
            });


            const interval = setInterval(() => {
                updateBalances();
            }, 1000 * 60);
            return () => clearInterval(interval);
        }
    }, []);


    const stake = async () => {
        try {
            // eslint-disable-next-line no-undef
            const amount = BigInt(Number.parseFloat(getValues().amount) * Math.pow(10, 9));
            console.log('stake amount:', amount);
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const DAI = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
            const TOKEN_CONTRACT = new ethers.Contract(TOKEN_CONTRACT_ADDRESS, TOKEN_CONTRACT_ABI, signer);
            const approveRes = await TOKEN_CONTRACT.approve(CONTRACT_ADDRESS, amount);
            console.log('approveRes', approveRes);
            const depositRes = await DAI.deposit(amount);
            console.log('depositRes', depositRes);
            updateBalances();
            resetField('amount');
        } catch (err) {
            console.log('Error while fetching contract data:', err);
            if (!err.message.includes('user rejected action')) {
                toastr.error(err, 'Oops! An error occurred', {displayDuration: 6000});
            }
            resetField('amount');
        }
    };

    const withdrawal = async () => {
        try {
            // eslint-disable-next-line no-undef
            const amount = BigInt(Number.parseFloat(getValues().amount) * Math.pow(10, 9));
            // eslint-disable-next-line no-undef
            // const amount = BigInt(Number.parseFloat(getValues().amount) * 10 ** 18);
            console.log('stake withdrawal:', amount);
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const DAI = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
            await DAI.withdraw(amount);
            updateBalances();
            resetField('amount');
        } catch (err) {
            console.log('Error while fetching contract data:', err);
            if (!err.message.includes('user rejected action')) {
                toastr.error(err, 'Oops! An error occurred', {displayDuration: 6000});
            }
            resetField('amount');
        }
    };

    const [isEnableWithdrawal, setEnableWithdrawal] = useState(false);
    const [isInputEmpty, setInputEmpty] = useState(true);

    const onChange = (event) => {
        const amount = Number.parseFloat(event.target.value);
        const balance = Number.parseFloat(stakedBalance);
        setEnableWithdrawal(amount <= balance && amount !== 0);
        setInputEmpty(event.target.value === '' || amount === 0);
    };


    return (
        <div className="Staking">
            <style>{'body { background-color: #000; }'}</style>
            <div style={{marginBottom: '30px', textAlign: 'center'}}>
                {!loggedIn && (
                    <button
                        onClick={connectWallet}
                        className="ConnectedButton">
                        Connect Wallet
                    </button>
                )}
            </div>
            <div className="StakingWrapper">
                <div className="StakingItem">
                    <h3>$PepeFi</h3>
                    <h2>STAKING</h2>
                    <p>Stake your $PepeFi and earn rewards immediately</p>
                    <div className="StakingCalculation">
                        <h2>CALCULATION</h2>
                        <div className="StakingCalculationWrapper">
                            <div className="StakingCalculationItem">
                                <img src={time} alt=""/>
                                <div>
                                    <h3>TIME</h3>
                                    <p>60 DAYS</p>
                                </div>
                            </div>
                            <div className="StakingCalculationItem">
                                <img src={apy} alt=""/>
                                <div>
                                    <h3>APY</h3>
                                    <p>up to 100%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="StakingForm">
                    <div className="StakingFormHeader">
                        <h3>STAKE $PepeFi</h3>
                        <button className="StakingButton" onClick={redirectTo}>BUY $PepeFi</button>
                    </div>

                    <form>
                        <div>
                            <label className="StakingLabel">
                                <div className="StakingLabelBalance">
                                    <div>Amount</div>
                                    <div className="StakingLabelBalanceSection">
                                        <div>
                                            Staked: <span style={{
                                            color: '#daeb6e',
                                            fontSize: '16px'
                                        }}>{`${toFixed(stakedBalance)} (+${toFixed(earnedRewards)}) $PepeFi`}</span>
                                        </div>
                                        <div> Balance: <span
                                            style={{
                                                color: '#daeb6e',
                                                fontSize: '16px'
                                            }}>{toFixed(walletBalance)} $PepeFi</span>
                                        </div>
                                    </div>
                                </div>

                            </label>
                            <input {...register('amount', {required: true})} onChange={onChange} placeholder="0"
                                   className="StakingInput"
                                   type="number"/>
                        </div>

                        <div className="StakingButtonGroup">
                            <button disabled={isInputEmpty} onClick={stake} style={{width: '49%'}} type="button"
                                    className="StakingButton">STAKE
                            </button>
                            <button
                                disabled={!isEnableWithdrawal || isInputEmpty}
                                onClick={withdrawal}
                                title={!isEnableWithdrawal ? 'Please withdraw only your amount, all your rewards will be automatically deducted and transferred to your wallet.' : ''}
                                style={{width: '49%'}} type="button"
                                className="StakingButton">WITHDRAWAL
                            </button>
                        </div>

                    </form>

                    <img className="LorisImage" src={frog} alt=""/>
                </div>
            </div>

        </div>
    );
}

export default StakingComponent;
