import './App.css';
import {CrossChainSwapComponent} from './shared/components/CrossChainSwapComponent/CrossChainSwapComponent';
import Header from './shared/components/HeaderComponent/HeaderComponent';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';
import StakingComponent from './shared/components/StakingComponent/StakingComponent';

function App() {
    return (
        <div className="App">
            <HashRouter>
                <Header/>
                <main>
                    <Routes>
                        <Route index path="/" exact element={<Navigate to="/crosschainswap"/>}/>
                        <Route path="/*" element={<Navigate to="/crosschainswap"/>}/>
                        <Route path="/crosschainswap" element={<CrossChainSwapComponent/>}/>
                        <Route path="/staking" element={<StakingComponent/>}/>
                    </Routes>
                </main>
            </HashRouter>
        </div>
    );
}

export default App;
