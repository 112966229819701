import './HeaderComponent.css';
import { NavLink } from 'react-router-dom';

function Header() {
    const navigationLinks = [
        {
            title: 'HOME',
            url: 'https://www.gorillaswap.online/'
        },
        {
            title: 'ABOUT',
            url: 'https://www.gorillaswap.online/#about'
        },
        //{
      //      title: 'FEATURES',
       //     url: 'https://www.pepefinance.xyz/#feature'
       // },
        {
            title: 'ROADMAP',
            url: 'https://www.gorillaswap.online/#roadmap'
        },
        {
            title: 'TOKENOMICS',
            url: 'https://www.gorillaswap.online/#token'
        },
        {
            title: 'WhitePeper',
            url: 'https://gorillaswap.gitbook.io/gorilla/'
        },
    ];
    const dappMenuItems = [
        {
            title: 'Buy $Gorilla',
            url: 'https://pancakeswap.finance/swap?outputCurrency=0x3e59f9a99c4e07c02b54e1d6657528c2546fe4ea',
            important: 'important'
        },
        //{
        //    title: 'Earn',
        //    url: '/staking',
        //    important: 'important'
        //},
       // {
       //     title: 'Launch DApp',
       //     url: '/crosschainswap',
       //     important: 'important'
       // },
    ]
    const closeBurgerMenu = () => {
        document.getElementById('menu-toggle').checked = false;
    };
    return (
        <div className="HeaderWrapper">
            <header className="Header">
                <section className="top-nav">
                    <input id="menu-toggle" type="checkbox" />
                    <label className="menu-button-container" htmlFor="menu-toggle">
                        <div className="menu-button"></div>
                    </label>
                    <ul className="menu">
                        {navigationLinks.map(el => {
                            return <li className={el.important ? "NavigationItem dappElement" : "NavigationItem"} key={el.title}>
                                <NavLink onClick={closeBurgerMenu} activeclassname="active" className={`NavigationLink ${el.important}`}
                                    to={el.url}>{el.title}</NavLink>
                            </li>;
                        })}
                        <li className="DappNavifationItem">
                            <ul className="dappmenu">
                                {dappMenuItems.map(el => {
                                    return <li className={el.important ? "dappElement" : "NavigationItem"} key={el.title}>
                                        <NavLink onClick={closeBurgerMenu} activeclassname="active" className={`NavigationLink ${el.important}`}
                                            to={el.url}>{el.title}</NavLink>
                                    </li>;
                                })}
                            </ul>
                        </li>
                    </ul>

                </section>
            </header>
        </div>
    );
}

export default Header;
